<template>
    <tab-po></tab-po>
</template>
<script>
import TabPo from "./purchase-order/Main.vue";
export default {
    components: {
        TabPo,
    },
    data() {
        return {
            activeTab: "Po",
        }
    },
}
</script>
